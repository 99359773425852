<template>
    <v-container class="pa-2">
        <v-row>
            <v-col cols="2">
                <v-label>Rendimiento por:</v-label>
            </v-col>
            <v-col cols="10">
                <v-select dense solo outlined flat hide-details :items="tipo_rendimiento" item-text="text" item-value="value"
                    v-model="tipo_rendimiento_seleccionado" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_participante'">Participante:</v-label>
                <!-- <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_grupo'">Grupos:</v-label> 
                <v-label v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">Cursos:</v-label> -->
            </v-col>
            <v-col cols="10">
                <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_participante'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="participante_seleccionado" 
                    :items="participantes" 
                    item-key="id" 
                    item-text="full_nombre" 
                    item-value="rut" 
                    prepend-inner-icon="search"
                    @change="getRendimientoParticipante"
                />
                <!-- <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_grupo'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="id_grupo_curso_seleccionado" 
                    :items="grupos_curso" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />
                <v-autocomplete v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'"
                    dense solo outlined flat  hide-details clearable 
                    v-model="curso_seleccionado" 
                    :items="cursos" 
                    item-key="id" 
                    item-text="nombre" 
                    item-value="id" 
                    prepend-inner-icon="search" 
                />  -->
            </v-col>
        </v-row>
        <div v-if="tipo_rendimiento_seleccionado === 'rendimiento_curso'">
            <v-row>
                <v-col cols="2">
                    <v-label>Grupo Acceso:</v-label> 
                </v-col>
                <v-col cols="10">
                    <v-autocomplete
                        dense solo outlined flat  hide-details clearable 
                        v-model="id_grupo_curso_seleccionado" 
                        :items="grupos_curso" 
                        item-key="id" 
                        item-text="nombre" 
                        item-value="id" 
                        prepend-inner-icon="search" 
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-label>Curso del grupo:</v-label>
                </v-col>
                <v-col cols="10">
                    <v-autocomplete
                        dense solo outlined flat  hide-details clearable 
                        v-model="curso_seleccionado" 
                        :items="cursos" 
                        item-key="id" 
                        item-text="nombre" 
                        item-value="id" 
                        prepend-inner-icon="search" 
                    /> 
                </v-col>
            </v-row>
        </div>
        <v-row justify="end">
            <v-col cols="auto" class="mr-0 pr-0" v-if="isData && tipo_rendimiento_seleccionado === 'rendimiento_participante'">
                <v-btn color="primary" class="mr-1" @click="generateReportePDF">
                    <v-icon left>picture_as_pdf</v-icon>
                    reporte pdf
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import moment from 'moment'
import { axiosHelper } from '@/helpers'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '@/assets/logo.jpg'; // logo para incluir en los reportes
export default{
    props: {
        activeSubTabCursosInternos:{
            type: Number
        },
        isData: {
            type: Boolean,  
            default: false, 
        }
    },
    data:()=>({
        //
        tipo_rendimiento:[
            { text: 'participante', value: 'rendimiento_participante' },
            // { text: 'grupo', value: 'rendimiento_grupo' },
            { text: 'curso ', value: 'rendimiento_curso' } 
        ],
        //
        data:{},
        //
        tipo_rendimiento_seleccionado:null,
        //
        participantes:[],
        participante_seleccionado:null,
        //
        grupos_curso:[],
        id_grupo_curso_seleccionado:null,
        //
        cursos: [],
        curso_seleccionado: null,
    }),
    created(){
        this.tipo_rendimiento_seleccionado='rendimiento_participante';
        this.fetchGrupoCursos();
    },
    mounted() {
        this.getParticipantes();
    },
    watch:{
        id_grupo_curso_seleccionado(newGrupoCursoId) {
            this.$emit('filter-changed');
            if (newGrupoCursoId) {
                this.fetchCursosPorGrupo(newGrupoCursoId);
            }
            if(newGrupoCursoId == null){
                this.$emit('clear-table');
                this.curso_seleccionado = null;
            }
        },
        curso_seleccionado(newCursoId){
            if (newCursoId){
                this.getRendimientoCurso();
            }
            if(newCursoId == null){
                this.$emit('clear-table');
            }
        },
        participante_seleccionado(participanteId){
            if(participanteId == null){
                this.$emit('clear-table');
            }
        },
        tipo_rendimiento_seleccionado(nuevoTipoRendimiento, anteriorTipoRendimiento){
            if(nuevoTipoRendimiento !== anteriorTipoRendimiento){
                this.participante_seleccionado = null;
                this.curso_seleccionado = null;
                this.id_grupo_curso_seleccionado = null;
                this.$emit('clear-table');
            }
        }
    },
    methods:{
        async fetchCursosPorGrupo(grupoCursoId) {
            try {
                const response = await axiosHelper.get(`edu/cursos/nombres/?grupo_curso_id=${grupoCursoId}`);
                if (response.data.length === 1) {
                    this.cursos = response.data;
                    this.curso_seleccionado = this.cursos[0].id;

                } else {
                    this.cursos = [{ id: null, nombre: 'Seleccione...' }, ...response.data];
                    this.curso_seleccionado = null;
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        },
        async fetchGrupoCursos() {
            try {
                const response = await axiosHelper.get('edu/grupos/nombres');
                this.grupos_curso = [{ id: null, nombre: 'Seleccione ...' }, ...response.data];
                this.id_grupo_curso_seleccionado = null;

            } catch (error) {
                console.error("Error fetching group names:", error);
            }
        },
        async getParticipantes(){
            try {
                const response = await axiosHelper.get('edu/usuarios/participantes');
                this.participantes = [{ id: null, full_nombre: 'Seleccione un participante...' }, ...response.data];
                this.participante_seleccionado = null;
            } catch (error) {
                console.error("Error al obtener los participantes:", error);
            }
        },
        async getRendimientoCurso(){
            try{
                const params = {
                    'grupo_id':this.id_grupo_curso_seleccionado,
                    'curso_id':this.curso_seleccionado
                }
                const response = await axiosHelper.post('edu/stats/type/?d=rendimiento_curso', params);
                this.data = {
                    'tipo_data':'rendimiento_curso',
                    'data':response.data
                }
                this.$emit('datos-recibidos', this.data);
            }catch(error){
                console.error("Error al obtener el rendimiento del curso:", error);
            }
        },
        async getRendimientoParticipante(rut){
            try{
                const params ={rut}
                const response = await axiosHelper.post('edu/stats/type/?d=persona', params);
                let rendimiento_participante = [];
                for(let grupo of response.data.rendimiento.logs){
                    for(let registro of grupo.registros){
                        const evaluacion = {
                            id_evaluacion:registro.evaluacion.id,
                            nombre:registro.evaluacion.nombre,
                            nombre_curso:registro.evaluacion.curso,
                            nombre_grupo:grupo.grupo,
                            cantidad_preguntas: registro.respuestas.length,
                            total_desaciertos:registro.total_incorrectas,
                            duracion:registro.duracion.split(".")[0] + " (hh:mm:ss)"
                        };
                        const respuestas = [];
                        for(let respuesta of registro.respuestas){
                            respuestas.push(respuesta);
                        }
                        evaluacion.respuestas = respuestas;
                        rendimiento_participante.push(evaluacion);
                    }
                }
                this.data = {
                    'tipo_data':'rendimiento_persona',
                    'rendimiento_participante_pdf': response.data,
                    'rendimiento_participante_table':rendimiento_participante
                }
                this.$emit('datos-recibidos', this.data);
            }catch(error){
                console.error("Error al obtener el rendimiento del participante:", error);
            }
        },
        generateReportePDF(){
            //
            const doc = new jsPDF();
            // const marginTop = 20;   // Margen superior
            // const marginLeft = 20;  // Margen izquierdo
            // const marginRight = 20; // Margen derecho
            const margin = 20; // tanto para el margen izquierdo como el derecho y superior
            const pageWidth = doc.internal.pageSize.getWidth(); // Ancho de la página
            const pageHeight = doc.internal.pageSize.getHeight(); // Altura de la página
            const contentWidth = pageWidth - margin*2; // Ancho útil del contenido
            const anchoLinea = { maxWidth: 160 }; 
            const lineHeight = 6;  // altura de linea
            const headerHeight = 5; // altura de la cabecera de la hoja del documento
            const fechaActual = new Date();
            //
            const persona = this.data.rendimiento_participante_pdf.persona;
            const evaluaciones = this.data.rendimiento_participante_pdf.evaluaciones;
            const rendimiento = this.data.rendimiento_participante_pdf.rendimiento;
            const tiempo = this.data.rendimiento_participante_pdf.tiempo;
            const respuestas_incorrectas_data = this.data.respuestas_incorrectas_data;
            const tipo_data = this.data.tipo_data;
            //
            const addHeader = () => {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.addImage(logo, 'PNG', margin + 150, margin / 2, 20, 10); // Ajusta la posición según el margen
                }
            };
            const addFooter = () => {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setFont('helvetica', 'normal');
                    const text = `${fechaActual} Página ${i} de ${pageCount}`;
                    const textWidth = doc.getTextWidth(text);
                    doc.text(text, pageWidth - margin - textWidth, pageHeight - margin / 2);
                }
            };
            const startContentY = margin + headerHeight;
            doc.setFontSize(22);
            doc.setFont('helvetica', 'bold');
            const tituloY = startContentY; // Posición Y del título considerando el margen y el encabezado
            let titulo = "";
            if(tipo_data === 'rendimiento_curso'){
                titulo = doc.splitTextToSize("Reporte de rendimiento por curso" , pageWidth - margin * 2);
            }
            if(tipo_data === 'rendimiento_persona'){
                titulo = doc.splitTextToSize("Reporte de rendimiento por participante", pageWidth - margin * 2);
            }
            doc.text(titulo, margin, tituloY, anchoLinea);
            let startY = tituloY + (titulo.length * 10);
            // Subtítulo y descripción
            doc.setFontSize(12);
            if(tipo_data === 'rendimiento_curso'){
                doc.setFont('times', 'italic');
                const lines = doc.splitTextToSize("Este reporte proporciona un indicador del total de la cantidad de respuestas incorrectas para las evaluaciones del curso " + "'"+"pepe"+"'", 160);
                doc.text(lines, margin, startY);
                startY += 6 * lines.length;
                doc.setLineWidth(0.1);
                doc.line(margin, startY, pageWidth - margin, startY); // Coordenadas (x1, y1, x2, y2)
                startY += 10;
            }
            if(tipo_data ==='rendimiento_persona'){
                //
                startY = this.setLine(doc, startY, pageWidth, margin);
                startY = this.setHeader(doc,"DATOS DEL PARTICIPANTE", margin, startY);
                startY = this.setLine(doc, startY, pageWidth, margin);

                // todos estos datos se van a una tabla
                var body1 = [
                    {name:"Nombre",                 valor:persona.first_name + ' ' + persona.last_name},
                    {name:"Rut",                    valor:persona.rut},
                    {name:"Cargo",                  valor:persona.gerencia.nombre},
                    {name:"Empresa",                valor:persona.empresa.nombre}
                ];
                
                doc.autoTable({
                    // head: [['Parámetro', 'porcentaje']],
                    body: body1,
                    startY: startY + 2,
                    margin: { top: startContentY, left: margin, right: margin },
                    didDrawPage: this.addHeader,
                    headStyles: {
                        halign: 'center' // Alineación global para todos los encabezados (opcional)
                    },
                    theme: 'grid',  // 'striped', 'grid' or 'plain'
                    columnStyles: {
                        1: { halign: 'right' }
                    },
                });
                startY = doc.lastAutoTable.finalY + 10;
                startY = this.setLine(doc, startY, pageWidth, margin);
                startY = this.setHeader(doc,"INDICADORES", margin, startY);
                startY = this.setLine(doc, startY, pageWidth, margin);
                //
                var body5 = [
                    ["Evaluaciones realizadas",               String(evaluaciones.rel_aprob.toFixed(2)) + " %"],
                    ["Evaluaciones no realizadas",            String(evaluaciones.rel_inv.toFixed(2)) + " %"],
                    ["Respuestas correctas",                  String(rendimiento.rel_correctas.toFixed(2)) + " %"],
                    ["Respuestas incorrectas",                String(rendimiento.rel_incorrectas.toFixed(2)) + " %"]
                ];
                
                doc.autoTable({
                    head: [['Parámetro', 'Porcentaje']],
                    body: body5,
                    startY: startY + 2,
                    margin: { top: startContentY, left: margin, right: margin },
                    didDrawPage: this.addHeader,
                    headStyles: {
                        halign: 'center' // Alineación global para todos los encabezados (opcional)
                    },
                    theme: 'grid',  // 'striped', 'grid' or 'plain'
                    columnStyles: {
                        1: { halign: 'right' }
                    },
                });
                startY = doc.lastAutoTable.finalY + 10;
                startY = this.setHeader(doc,"ESTADO DE LAS EVALUACIONES", margin, startY);
                startY = this.setLine(doc, startY, pageWidth, margin);
                // Tabla de resumen de avaluaciones
                if(evaluaciones.logs.length > 0){
                    const body_evaluacion = evaluaciones.logs.map(log => [
                        log.evaluacion.nombre,
                        log.curso,
                        moment(log.fecha_inicio).format('DD/MM/YYYY HH:mm:ss') + ' hrs',
                        moment(log.fecha_termino).format('DD/MM/YYYY HH:mm:ss') + ' hrs',
                        log.is_completado ? 'completado':'pendiente'
                    ]);
                    doc.autoTable({
                        head: [['Evaluación', 'Curso','Disponible desde','Hasta','estado']],
                        body: body_evaluacion,
                        startY: startY + 2,
                        margin: { top: startContentY, left: margin, right: margin },
                        didDrawPage: this.addHeader,
                        theme: 'grid',
                        headStyles: {
                            halign: 'center' // Alineación global para todos los encabezados (opcional)
                        },
                        columnStyles: {
                            1: { halign: 'center' }
                        },
                    });
                    startY = doc.lastAutoTable.finalY + 10;
                }
                //
                startY = this.setLine(doc, startY, pageWidth, margin);
                doc.setFont('helvetica', 'bold');
                startY = this.ponerTextoEnPdf(doc,"TIEMPO DE RESPUESTA",lineHeight,pageHeight,margin,contentWidth,startY);
                startY = this.setLine(doc, startY, pageWidth, margin);
                startY += 5;
                // tabla de tiempo de respuestas
                let dataArray = []; // Array bidimensional que almacenará los datos
                if(tiempo.logs.length > 0){
                    tiempo.logs.forEach((item,index)=>{
                        const tiempo_data = item.puntajes.map(curso => [
                            curso.curso,
                            moment(curso.finalizado).format("DD/MM/YYYY HH:mm:ss"),
                            // curso.score,
                            curso.tag
                        ]);
                        dataArray.push(...tiempo_data);
                        
                    });
                    doc.autoTable({
                        head: [['Curso','Realización','estado']],
                        body: dataArray,
                        startY: startY + 2,
                        margin: { top: startContentY, left: margin, right: margin },
                        theme: 'grid',
                        didDrawPage: this.addHeader,
                        headStyles: {
                            halign: 'center' // Alineación global para todos los encabezados (opcional)
                        },
                        columnStyles: {
                            1: { halign: 'center' }
                        },
                    });
                    startY = doc.lastAutoTable.finalY + 10;
                }
                //
                startY = this.setLine(doc, startY, pageWidth, margin);
                startY = this.setHeader(doc,"DETALLE", margin, startY);
                startY = this.setLine(doc, startY, pageWidth, margin);
                startY += 5;
                // Tabla de detalle de evaluaciones
                if(rendimiento.logs.length > 0) {
                    // itera sobre los grupos de acceso
                    rendimiento.logs.forEach((item,index) => {
                        const grupoAcceso = item.grupo;
                        // itera sobre las evaluaciones de cada grupo.
                        var registros = item.registros;
                        registros.forEach((item,index)=> {
                            //
                            doc.setFont("times", "bold");
                            startY = this.ponerTextoEnPdf(doc,"Evaluación: "+item.evaluacion.nombre,lineHeight,pageHeight,margin,contentWidth,startY);
                            // doc.text("Evaluación: "+item.evaluacion.nombre, margin, startY);
                            // startY += 5;
                            //
                            var body2 = [
                                {name:"Curso",                  valor:item.evaluacion.curso},
                                {name:"Grupo de acceso",        valor:grupoAcceso},
                                {name:"Cantidad de preguntas",  valor:item.respuestas.length},
                                {name:"Total desaciertos",      valor:item.total_incorrectas},
                                {name:"Tiempo (HH:MM:SS)",      valor:item.duracion.split(".")[0]},
                            ];
                            
                            doc.autoTable({
                                // head: [['name', 'valor']],
                                body: body2,
                                startY: startY + 2,
                                margin: { top: startContentY, left: margin, right: margin },
                                didDrawPage: this.addHeader,
                                headStyles: {
                                    halign: 'center' // Alineación global para todos los encabezados (opcional)
                                },
                                theme: 'grid',  // 'striped', 'grid' or 'plain'
                                columnStyles: {
                                    1: { halign: 'right' }
                                },
                            });
                            startY = doc.lastAutoTable.finalY + 10;

                            // iterar sobre las preguntas de cada evaluacion
                            const body = item.respuestas.map(respuesta => [
                                    respuesta.pregunta__texto,
                                    respuesta.incorrectas
                                ]);
                            doc.autoTable({
                                head: [['Pregunta', 'Desaciertos']],
                                body: body,
                                startY: startY + 2,
                                margin: { top: startContentY, left: margin, right: margin },
                                didDrawPage: this.addHeader,
                                headStyles: {
                                    halign: 'center' // Alineación global para todos los encabezados (opcional)
                                },
                                columnStyles: {
                                    1: { halign: 'center' }
                                },
                            });
                            startY = doc.lastAutoTable.finalY + 10;
                        });
                    });
                } else {
                    doc.setFont('times', 'normal');
                    doc.setFontSize(12);
                    doc.text("Participante no registra evaluaciones.", margin, startY + 5);
                    startY += 20;
                }
            }
            if(tipo_data ==='rendimiento_curso') {
                respuestas_incorrectas_data.forEach(item => {
                    const cursoNombre = doc.splitTextToSize(item.nombre.toLowerCase(), pageWidth - margin * 2);
                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    // doc.text("Evaluación: "+ cursoNombre, margin, startY ,anchoLinea);
                    const lines = doc.splitTextToSize("Evaluación: "+ cursoNombre, 160);
                    doc.text(lines, margin, startY);
                    startY += 6 * lines.length;
                    doc.setFontSize(10);
                    doc.setFont('times', 'italic');
                    doc.text("Curso: "+ item.curso_nombre, margin, startY);

                    if(item.preguntas.length > 0) {
                        startY += cursoNombre.length * 5;

                        const body = item.preguntas.map(preguntas => [
                            preguntas.texto,
                            preguntas.cantidad_incorrectas
                        ]);

                        doc.autoTable({
                            head: [['Pregunta', 'veces incorrectas']],
                            body: body,
                            startY: startY + 2,
                            margin: { top: startContentY, left: margin, right: margin },
                            didDrawPage: this.addHeader,
                            headStyles: {
                                halign: 'center' // Alineación global para todos los encabezados (opcional)
                            },
                            columnStyles: {
                                1: { halign: 'center' }
                            },
                        });

                        startY = doc.lastAutoTable.finalY + 10;
                    } else {
                        doc.setFont('times', 'normal');
                        doc.setFontSize(12);
                        doc.text("Sin resultados para este tipo de reportes", margin, startY + 5);
                        startY += 20;
                    }
                });
            }
            addHeader();
            addFooter();    
            if(persona){
                doc.save('reporte-desempeno-'+persona.first_name +'-'+ persona.last_name+'-'+persona.rut+'.pdf');
            }
            else{
                doc.save('reporte-desempeno.pdf');
            }
        },
        setLine(doc, startY, pageWidth, margin){
            doc.setLineWidth(0.1);
            doc.line(margin, startY, pageWidth - margin, startY); // Coordenadas (x1, y1, x2, y2)
            return startY += 5;
        },
        setHeader(doc, encabezado, margin, startY){
            doc.setFont('helvetica', 'bold');
            doc.text(encabezado, margin, startY);
            doc.setFont("times", "italic");
            return startY +=2;
        }, 
        setField(doc, campo, margin, startY,pageHeight){
            doc.setFont("times", "bold");
            doc.text(campo, margin, startY);
        },
        // considerar meter el campo y el valor en un solo metodo
        setFieldValue(doc, valorCampo, margin, desplazamientox, startY, anchoLinea, lineHeight, pageHeight) {
            // Configura la fuente
            doc.setFont("times", "italic");
            // Divide el texto en líneas que se ajusten al ancho
            const text = doc.splitTextToSize(valorCampo, 50); // 50 corresponde al ancho de la linea
            // Recorre las líneas y verifica si se necesita un salto de página
            text.forEach((line, index) => {
                // Calcula la posición actual de la línea
                const posY = startY + index * lineHeight;
                // Si la línea está fuera del espacio disponible, agrega una nueva página
                if (posY >= pageHeight - margin) {
                    doc.addPage(); // Salta a una nueva página
                    startY = margin; // Reinicia la posición Y al margen superior
                }
                // Dibuja la línea de texto
                doc.text(line, margin + desplazamientox, startY + index * lineHeight);
            });
            // Calcula y devuelve el nuevo valor de startY
            return startY + text.length * lineHeight;
        },
        setNombreGrupo(doc,nombre,startY,lineHeight,margin){
            doc.setFontSize(10);
            doc.setFont('times', 'italic');
            const nombreFormateado = doc.splitTextToSize( "Grupo de acceso: " + nombre.toLowerCase(), 160);
            // Renderiza cada línea del texto
            nombreFormateado.forEach((line, index) => {
                doc.text(line, margin, startY + index * lineHeight);
            });
            return lineHeight * nombreFormateado.length;
        },
        ponerTextoEnPdf(doc,text,lineHeight,pageHeight,margin,contentWidth,startY){
            const lines = doc.splitTextToSize(text, contentWidth); // Divide el texto para ajustarse al ancho útil
            let cursorY = startY; // Posición inicial en Y
            lines.forEach(line => {
                if (cursorY + lineHeight > pageHeight - margin) {
                    doc.addPage(); // Salta a una nueva página si se excede el espacio vertical
                    cursorY = margin; // Reinicia el cursor en la nueva página
                }
                doc.text(line, margin, cursorY);
                cursorY += lineHeight;
            });
            return cursorY;
        }
    }

}
</script>